/* tslint:disable */
/* eslint-disable */
/**
 * Unleash API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.10.0-beta.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConstraintSchema
 */
export interface ConstraintSchema {
    /**
     * 
     * @type {string}
     * @memberof ConstraintSchema
     */
    contextName: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintSchema
     */
    operator: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConstraintSchema
     */
    values?: Array<string>;
}

export function ConstraintSchemaFromJSON(json: any): ConstraintSchema {
    return ConstraintSchemaFromJSONTyped(json, false);
}

export function ConstraintSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConstraintSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contextName': json['contextName'],
        'operator': json['operator'],
        'values': !exists(json, 'values') ? undefined : json['values'],
    };
}

export function ConstraintSchemaToJSON(value?: ConstraintSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contextName': value.contextName,
        'operator': value.operator,
        'values': value.values,
    };
}

